import React from "react";
import style from "./Sidebar.module.css";
import sidebarLogo from "../../assets/img/logo.svg";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect, useLayoutEffect } from "react";

const SIDEBAR_ITEMS = [
  {
    id: "1",
    icon: "fa fa-home",
    text: "Home",
    path: "/",
  },
  {
    id: "2",
    icon: "fa fa-search",
    text: "Explore",
    path: `/searchList?cityId=${localStorage.getItem("selectedCity")}&adults=2&children=0&date=${(new Date()).toISOString().split('T')[0]}`,
  },
  {
    id: "3",
    icon: "fa fa-heart",
    text: "Trips",
    path: "/trips",
    disable: true
  },
  {
    id: "4",
    icon: "fa fa-user",
    text: "Profile",
    path: "/profile",
    disable: true
  },
];


const Sidebar = ({ page }) => {

  const [mobile, setMobile] = useState(false);
  const [isVisible, setIsVisible] = useState(
    localStorage.getItem("sidebarVisibility") === "true" ? true : false
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowEventAttached, setWindowEventAttached] = useState(false);

  function changeWindowWith() {
    setWindowWidth(window.innerWidth);
  }

  useLayoutEffect(() => {
    if (!windowEventAttached) {
      setWindowEventAttached(true)
      window.addEventListener('resize', changeWindowWith);

      return () => {
        window.removeEventListener('resize', changeWindowWith);
      }
    }
  }, [windowEventAttached])

  useEffect(() => {
    if (windowWidth < 768) {
      setIsVisible(false)
      setMobile(true)
    }
  }, [windowWidth])

  useEffect(() => {
    localStorage.setItem("sidebarVisibility", String(isVisible));
  }, [isVisible]);

  return (
    <>
      <div className={`${style.showSideNavBtn} ${page === "details" && mobile ? style.showSideNavBtnDetails : ""}`} onClick={() => setIsVisible(true)}>
        <div className="fas fa-angle-double-right fa-2x"></div>
      </div>

      <div className={`${isVisible ? style.showAnimation : style.hideAnimation} ${mobile ? style.sideNavMobile : ""} ${style.sidenav}`}>

        <Link aria-label="Boat Trips Logo " to="https://www.boattrips.al/"><img className={style.logo} src={sidebarLogo} alt="Boat Trip | Boat Tours" /></Link>

        <div className={style.buttonsWrapper}>
          {SIDEBAR_ITEMS.map((item) => (
            <div key={`sidebar-item-${item.id}`}>
              {!!item.disable ? '' :
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    style.button + (isActive ? " " + style.activeButton : "")
                  }
                >
                  <i className={item.icon}></i>
                  <span className={style.buttonText}>{item.text}</span>
                </NavLink>
              }
            </div>
          ))}
        </div>

        <div
          className={`${style.hideSideNavBtn} ${page === "details" && mobile ? style.hideSideNavBtnDetails : ""}`}
          onClick={() => setIsVisible(false)}>
            <div className="fas fa-angle-double-left fa-2x"></div>
        </div>

        {/* Placeholder div */}
        <div></div>

      </div>
    </>
  );
};
export default Sidebar;
